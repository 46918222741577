<template>
  <div>
    <div v-if="this.$route.query.packageId !== 'null'">
       <b-breadcrumb class="mb-2">
      <b-breadcrumb-item to="/orders/recurring">Packages</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link
          :to="{ name: 'commande', params: { id: this.$route.query.packageId } }"
          >Commandes</router-link
        >
      </b-breadcrumb-item>
      <b-breadcrumb-item active>Nouvelle demande</b-breadcrumb-item>
    </b-breadcrumb>
    </div>

    <div v-else>
       <b-breadcrumb class="mb-2">
      <b-breadcrumb-item to="/orders/recurring">Packages</b-breadcrumb-item>
      <b-breadcrumb-item active>Nouvelle demande</b-breadcrumb-item>
    </b-breadcrumb>
    </div>
    <new-client :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" />
    <b-card>
      <validation-observer #default="{}" ref="addNewOrder" tag="form">
        <!-- body -->
        <b-form >
           <!-- Client -->
           <b-row>
            <b-col cols="12" md="8" lg="8" sm="6" v-if="$route.query.client_hide == 'false'">
              <b-row>
                <b-col cols="12" class="pr-1">
                  <b-form-group label="Client" label-for="Client" v-if="getCustomer !== null">
                      <b-form-input id="customer" v-model="clientName" type="text"
                           readonly />
                    </b-form-group>
                  <validation-provider
                  v-else
                    #default="{ errors }"
                    name="Client"
                    rules="required"
                  >
                    <b-form-group label="Client" label-for="Client">
                      <v-select
                        id="customer"
                        v-model="forms.customer_id"
                        :reduce="(customer) => customer.id"
                        label="name"
                        :options="customerOptions"
                        :state="errors.length > 0 ? false : null"
                      >
                        <template v-slot:no-options>
                          <b-spinner
                            v-if="isCustomersWithoutPaginationLoading"
                            style="width: 2.5rem; height: 2.5rem"
                            class="align-middle text-info"
                          />
                          <div v-else>
                            <span class="mt-1 font-medium-1"
                              >Aucun client ne correspond à ces détails</span
                            >
                            <li
                              class="text-success mt-1 mb-1 font-medium-1"
                              style="text-align: center; cursor: pointer"
                              @click="isAddNewUserSidebarActive = true"
                            >
                              <feather-icon icon="PlusIcon" size="16" />
                              <span class="text-center align-middle ml-25"
                                >Inscrire le client</span
                              >
                            </li>
                          </div>
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="4" lg="4" sm="6" v-if="$route.query.client_hide == 'false'" class="d-flex align-items-center justify-content-end mb-2">
                <b-button variant="primary" class="mt-2 font-weight-normal font-medium-1" block
                  @click="isAddNewUserSidebarActive = true">
                  Inscrire le client
                </b-button>
            </b-col>
            </b-row>
          <b-row v-for="(form, index) in forms" :key="index" id="new-client-form">
             <!-- Requested service -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Service recherché"
                rules="required"
              >
                <b-form-group label="Service recherché" label-for="services">
                  <v-select
                    id="role"
                    v-model="form.service_id"
                    :reduce="(service) => service.id"
                    label="name"
                    :options="recurringServicesOption"
                    :state="errors.length > 0 ? false : null"
                    @input="getServiceEmployee"
                  >
                    <template v-slot:no-options>
                      <b-spinner
                        v-if="isServicesRecurringWithoutPaginationLoading"
                        style="width: 2.5rem; height: 2.5rem"
                        class="align-middle text-info"
                      />

                      <span v-else class="mt-1 font-medium-1"
                        >Ce service n'existe pas</span
                      >
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!--Client's employees -->
            <b-col md="6">
              <b-row>
                <b-col cols="12" class="pr-1">
                  <validation-provider
                    #default="{ errors }"
                    name="Employee"
                    rules="required"
                  >
                    <b-form-group label="Employee" label-for="Employee">
                      <v-select
                        multiple
                        id="employee"
                        v-model="form.employees"
                        :reduce="(employee) => employee.id"
                        label="name"
                        :options="form.employeeOptions"
                        :state="errors.length > 0 ? false : null"
                      >
                        <template v-slot:no-options>
                          <b-spinner
                            v-if="isEmployeeLoading"
                            style="width: 2.5rem; height: 2.5rem"
                            class="align-middle text-info"
                          />
                          <div v-else>
                            <span class="mt-1 font-medium-1"
                              >Aucun employé ne correspond à ces détails</span
                            >
                          </div>
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>

            <!-- Search employee -->
            <!-- <b-col
              md="6"
              class="mb-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Recherche ou gestion d'employé"
                rules="required"
              >
                <b-form-radio-group
                  v-model="form.search_employee"
                  :options="typeOptions"
                  :state="errors.length > 0 ? false : null"
                  value-field="item"
                  text-field="name"
                  class="demo-inline-spacing custom-control-success"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col> -->

            <!-- Employee firstname -->
            <b-col v-if="$route.query.search_employee === 0" md="6">
              <b-form-group label="Prénom de l'employé" label-for="firstname">
                <validation-provider
                  #default="{ errors }"
                  name=" prenom & nom "
                  rules="required"
                >
                  <b-form-input
                    id="firstname"
                    v-model="form.employee_first_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez le Prénom l'employé"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Employee phone number -->
            <!-- <b-col
              v-if="payload.search_employee === 0"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="Numéro de téléphone"
                rules="required"
              >
                <b-form-group
                  label="Numéro de téléphone"
                  label-for="phone"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      is-text
                      :class="errors[0] ? 'border-danger' : ''"
                    >
                      BJ (+229)
                    </b-input-group-prepend>
                    <cleave
                      id="phone"
                      v-model="phone"
                      class="form-control"
                      :class="errors[0] ? 'border-danger' : ''"
                      :raw="false"
                      :options="options.phone"
                      placeholder="12 34 56 78"
                    />
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col> -->

           
            <!-- Salaire net -->
            <b-col md="6">
              <validation-provider #default="{ errors }" name="salaire net" rules="required">
                <b-form-group label="Salaire net" label-for="employee_salary">
                  <b-form-input
                    id="employee_salary"
                    v-model="form.employee_salary"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez le salaire net de l'employé "
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- CNSS -->
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Cnss" rules="required">
                <b-form-group label="Déclarer à la cnss" label-for="cnss">
                  <b-form-select
                    id="cnss"
                    v-model="form.cnss"
                    :options="cnssOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- Nombre d'employés -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Nombre d'employé"
                rules="required"
              >
                <b-form-group label="Nombre d'employé" label-for="number_of_employees">
                  <b-form-input
                    id="number_of_employees"
                    v-model="form.number_of_employees"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez le nombre d'employé"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- Intervention frequency -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Fréquence d'intervation"
                rules="required"
              >
                <b-form-group
                  label="Fréquence d'intervation"
                  label-for="intervention_frequency"
                >
                  <b-form-select
                    id="intervention-frequency"
                    v-model="form.intervention_frequency"
                    :options="interventionFrequencyOptions"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- Other needs -->
            <b-col v-if="frequency === 'others'" md="6">
              <validation-provider
                #default="{ errors }"
                name="Frequence d'intervention"
                rules="required"
              >
                <b-form-group label="" label-for="intervention-frequency">
                  <b-form-textarea
                    id="intervention"
                    v-model="other_intervention_frequency"
                    placeholder="Plus d'infos la fréquence d'intervention"
                    rows="5"
                    no-resize
                    :state="errors.length > 0 ? false : null"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- Offer type -->
            <!-- <b-col md="6">
              <validation-provider #default="{ errors }" name="Type d'offre" rules="required">
                <b-form-group label="Type d'offre" label-for="offer-type">
                  <b-form-select id="offer-type" v-model="form.offer_type_id" :options="offerTypeOptions"
                    :state="errors.length > 0 ? false : null" />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col> -->

            <!-- Address -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="quartier du client"
                rules="required"
              >
                <b-form-group label="Quartier du client" label-for="address">
                  <b-form-input
                    id="address"
                    v-model="form.address"
                    placeholder="Quartier du client"
                    :state="errors.length > 0 ? false : null"
                    autocomplete="off"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- Other needs -->
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                name="Cahier des charges"
                rules="required"
              >
                <b-form-group label="Cahier des charges" label-for="needs">
                  <b-form-textarea
                    id="needs"
                    v-model="form.other_wish"
                    placeholder="Cahier des charges du client"
                    rows="5"
                    no-resize
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
           
            <b-col cols="12" md="4"  class="mb-4">
              <b-button
                variant="danger"
                :disabled="forms.length == 1"
                @click="removeItem(index)"
              >
                Supprimer
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <b-row>
           <!-- Reapet form -->
           <b-col cols="12" md="8"  class=" d-flex align-items-center justify-content-start " >
              <b-button
                variant="primary"
                :disabled="isRepeatLoading"
                @click="repeateAgain"
              >
                Ajouter un autre service
              </b-button>
            </b-col>
             <!-- submit and reset -->
        <b-col @click="applyAddNewOrder()" class=" d-flex align-items-center justify-content-end" md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="isAddNewOrderLoading"
          >
            {{ !isAddNewOrderLoading ? "Passer la demande" : "Chargement..." }}
          </b-button>
        </b-col>
        </b-row>
       
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BSpinner,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import { v4 as uuidv4 } from "uuid";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Cleave from "vue-cleave-component";
import NewClient from "./NewClient.vue";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.bj";

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    NewClient,
    BBreadcrumb,
    BBreadcrumbItem,
    Cleave,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      isRepeatLoading: false,
      cpt: 0,
      storeElement: 
      {
         address: "",
          cnss: "",
      },
      formEmployees: [],
      isAddNewUserSidebarActive: false,

      clientData: null,

      isAddNewOrderLoading: false,

      isCustomersListLoading: true,

      required,

      recurringServicesOption: [],

      customerOptions: [],
      isEmployeeLoading: false,
      forms: [
        {
          service_id: "",
          employee_salary: "",
          intervention_frequency: "",
          address: "",
          number_of_employees: "",
          cnss: "",
          employees: [],
          employeeOptions: [],
        },
      ],

      // typeOptions: [
      //   { item: 1, name: "À la recherche d'employé" },
      //   { item: 0, name: "Confier la gestion d'employé" },
      // ],

      interventionFrequencyOptions: [
        {
          value: 7,
          text: "Tous les jours",
        },
        {
          value: 1,
          text: "1 fois par semaine",
        },
        {
          value: 2,
          text: "2 fois par semaine",
        },
        {
          value: 3,
          text: "3 fois par semaine",
        },
        {
          value: 4,
          text: "4 fois par semaine",
        },
        {
          value: 5,
          text: "5 fois par semaine",
        },
        {
          value: 6,
          text: "6 fois par semaine",
        },
      ],

      offerTypeOptions: [
        {
          value: 1,
          text: "Confort",
        },
        {
          value: 2,
          text: "Premium",
        },
      ],
      cnssOptions: [
        {
          value: 1,
          text: "Oui",
        },
        {
          value: 0,
          text: "Non",
        },
      ],
      frequency: "",
      other_intervention_frequency: "",

      payload: {
        recurringOrders: [],
        user_id: "",
        search_employee: "",
      },

      phone: "",

      options: {
        phone: {
          phone: true,
          phoneRegionCode: "BJ",
        },
      },
    };
  },

  created() {
    this.isPackageClicked
      // this.getCustomersEmployees = this.formEmployees.forEach(element => {
      //   element.id
      //   element.full_name
      // });

    
  },

  computed: {
    ...mapGetters("services", [
      "getRecurringServices",
      "getRecurringServicesWithoutPagination",
    ]),
    ...mapGetters(["isServicesRecurringWithoutPaginationLoading", "isEmployeeGestionLoading", "isCustomersWithoutPaginationLoading"]),

    // ...mapGetters([
    //   'isRecurringServicesLoading',
    //   'isCustomersWithoutPaginationLoading',
    // ]),

    ...mapGetters("customers", [
      "getCustomers",
      "getCustomer",
      "getCustomersWithoutPagination",
      "getCustomersEmployeesWithoutPagination",
    ]),

    ...mapGetters(["isPackageClicked"]),

    clientName(){
      return this.getCustomer.first_name + ' ' +  this.getCustomer.last_name
    }
  },

  watch: {

    
    // isEmployeeGestionLoading(val) {
    //   if (val === false) {
    //    this.formEmployees.forEach((element) => {
    //     this.employeeOptions.push({
    //       id: element.id,
    //       name: element.full_name,
          // name: element.name
          // recurring_service: element.recurring_services.name
    //     });
    //   });
    //   }
    // },
   
    

      isCustomersWithoutPaginationLoading(val) {
      if (val === false) {
        this.getCustomersWithoutPagination.forEach((element) => {
          this.customerOptions.push({
            id: element.id,
            name: element.customer_full_name,
          });
        });
      }
    },
    
    isServicesRecurringWithoutPaginationLoading(val) {
      if (val === false) {
        this.getRecurringServicesWithoutPagination.forEach((element) => {
          this.recurringServicesOption.push({
            id: element.id,
            name: element.name,
          });
        });
      }
    },
  },

  mounted() {
    if (this.getRecurringServicesWithoutPagination.length != 0) {
      this.getRecurringServicesWithoutPagination.forEach((element) => {
        this.recurringServicesOption.push({
          id: element.id,
          name: element.name,
        });
      });
    }
    if (this.getCustomersWithoutPagination.length != 0) {
      this.getCustomersWithoutPagination.forEach((element) => {
        this.customerOptions.push({
          id: element.id,
          name: element.customer_full_name,
        });
      });
    }

    // if (this.formEmployees.length != 0) {
    //   this.formEmployees.forEach((element) => {
    //     this.employeeOptions.push({
    //       id: element.id,
    //       name: element.full_name,
          // name: element.name
          // recurring_service: element.recurring_services.name
    //     });
    //   });
    // }
    console.log("Employees", this.employeeOptions);

    

    this.configProAddressField();
  },

  methods: {
    ...mapActions("orders", ["makeOrderForAClientAction", "getDirectOrdersAction"]),
    ...mapActions("packages", ["newPackageOrderAction"]),

    ...mapActions("customers", [
      "getCustomersAction",
      "getCustomersWithoutPaginationAction",
      "getCustomersEmployeesWithoutPaginationAction",
      "addNewCustomerAction"
    ]),
    ...mapActions("packages", ["getDirectPackagesAction"]),

    getServiceEmployee(val){
      if(val !== null) {
      const servicesIndex =  this.forms.findIndex((element)=> element.service_id == val)
        this.isEmployeeLoading = true
      this.getCustomersEmployeesWithoutPaginationAction(val)
        .then((response) => {
          console.log(response.data)
          this.isEmployeeLoading = false
          this.forms[servicesIndex].employeeOptions=[]
          response.data.forEach((element) => {
            this.forms[servicesIndex].employeeOptions.push({
              id: element.id,
              name: element.full_name,
              // name: element.name,
              // recurring_service: element.recurring_services.name
            });
          });
        })
        .catch((error) => {
          console.log(error);
          this.isEmployeeLoading = false
        });
      }
      else{
         this.employeeOptions = []
      }
      

    },
    //package liste action
    applyGetDirectPackagesAction() {
      this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", true);
      this.getDirectPackagesAction()
        .then((response) => {
          this.clientPackage = response.data;
          this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
          console.log(response);
        })
        .catch(() => {
          this.$store.commit("SET_IS_DIRECT_PACKAGES_LOADING", false);
        });
    },

    // getClientEmployees() {
    //   this.getCustomersEmployeesWithoutPaginationAction()
    //     .then((response) => {
    //       response.data.forEach((element) => {
    //         this.employeeOptions.push({
    //           id: element.id,
    //           name: element.full_name,
    //           // recurring_service: element.recurring_services.name
    //         });
    //       });
    //       console.log("ClientsEmployees", this.employeeOptions);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    configProAddressField() {
      const input = document.getElementById("address");
      const options = {
        types: ["establishment"],
        componentRestrictions: { country: ["BJ"] },
      };
      this.autocomplete = new google.maps.places.Autocomplete(input, options);

      this.autocomplete.addListener("place_changed", () => {
        const place = this.autocomplete.getPlace();
        this.forms.address = place.name;
      });
    },
    repeateAgain() {
      this.$refs.addNewOrder.validate().then((success) => {
        if (success) {
           this.storeElement ={
           
            address: this.forms[this.cpt].address,
            
            cnss: this.forms[this.cpt].cnss,
          };
  
          this.forms.push({
            service_id: "",
            employee_salary: "",
            intervention_frequency: "",
            offer_type_id: "",
            address: this.storeElement.address,
            number_of_employees: "",
            cnss: this.storeElement.cnss,
            employeeOptions: [],
          });
          this.cpt += 1
        } else {
          reject();
        }
      });
    },

    removeItem(index) {
      this.forms.splice(index, 1);
      this.cpt -= 1
    },

    // containDuplicate(){
    //   let servicesId = []
    //   this.forms.forEach(element=> {
    //     servicesId.push(element.service_id)
    //   });
    //   console.log(servicesId)
    //   if(servicesId.length !== new Set(servicesId).length){
    //     return true
    //   }
    //   return false
    // },
    applyAddNewOrder() {
      this.$refs.addNewOrder.validate().then((success) => {
        if (success) {

          this.isAddNewOrderLoading = true;
          this.payload.recurringOrders = this.forms;
          this.payload.search_employee =
            this.$route.query.search_employee == "true"
              ? 1
              : 0;
          if (this.getCustomer !== null && this.$route.client_hide == 'false') {

            this.addNewCustomerAction(this.getCustomer)
              .then(response => {
                console.log('RESPONSE DATA', response)
                this.$store.commit(
                  'SET_IS_CUSTOMERS_WITHOUT_PAGINATION_LOADING',
                  true,
                )
                this.getCustomersWithoutPaginationAction().then(() => {
                  this.$store.commit(
                    'SET_IS_CUSTOMERS_WITHOUT_PAGINATION_LOADING',
                    false,
                  )
                  this.getCustomersAction().then(() => {

                  })

                  this.payload.user_id = response.data.id
                  console.log('ID CLIENT', this.payload.user_id)




                  this.newPackageOrderAction(this.payload)
                    .then((response) => {
                      console.log('NewOrder', response);

                      this.$toast(
                        {
                          component: ToastificationContent,
                          props: {
                            title: "Succès",
                            icon: "CheckIcon",
                            text: response.message,
                            variant: "success",
                          },
                        },
                        {
                          position: "top-center",
                        }
                      );

                      this.isAddNewOrderLoading = false;
                      this.$store.commit("packages/SET_CURRENT_PACKAGE", response.package);
                      localStorage.setItem("clientPackage", JSON.stringify(response.package));
                      console.log('PACKAGE COURRANT', response.package)
                      this.applyGetDirectPackagesAction();
                      this.$router.push({
                        path: `/packages/${response.package.id}/recurring/orders`,
                      });
                      // localStorage.removeItem('newCustomer')
                      this.$store.commit('CLEAR_CLIENT');
                    })
                    .catch(error => {
                      console.log(error)
                      this.$toast(
                        {
                          component: ToastificationContent,
                          props: {
                            title: 'Erreur',
                            icon: 'TriangleIcon',
                            text: error.response.data.message,
                            variant: 'danger',
                          },
                        },
                        {
                          position: 'top-center',
                        },
                      )
                    })


                })

              })

          }

          else {

            if (this.$route.query.client_hide == "false") {
              this.payload.user_id = this.forms.customer_id;
            } else {
              this.payload.user_id = JSON.parse(localStorage.getItem("clientPackage")).user.id;
            }


            console.log('storeElement', this.storeElement)


            this.newPackageOrderAction(this.payload)

              .then((response) => {
                console.log('NewOrder', response);

                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Succès",
                      icon: "CheckIcon",
                      text: response.message,
                      variant: "success",
                    },
                  },
                  {
                    position: "top-center",
                  }
                );
                this.$store.commit("packages/SET_CURRENT_PACKAGE", response.package);
                localStorage.setItem("clientPackage", JSON.stringify(response.package));
                console.log('PACKAGE COURRANT', response.package)
                this.applyGetDirectPackagesAction();
                this.$router.push({
                  path: `/packages/${response.package.id}/recurring/orders`,
                });
              })
              .catch((error) => {
                // console.log("NEW ORDER",error)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Erreur",
                      icon: "TriangleIcon",
                      text: error.response.data.message,
                      variant: "danger",
                    },
                  },
                  {
                    position: "top-center",
                  }
                );
                this.isAddNewOrderLoading = false;
                // localStorage.removeItem('newCustomer')
              });
          }

        }
      })
      .catch(error => {
                this.isAddNewOrderLoading = false;
                console.log(error)
                      this.$toast(
                        {
                          component: ToastificationContent,
                          props: {
                            title: 'Erreur',
                            icon: 'TriangleIcon',
                            text: error.response.data.message,
                            variant: 'danger',
                          },
                        },
                        {
                          position: 'top-center',
                        },
                      )
                      this.isAddNewUserSidebarActive = true
                    })


    },
  },
};
</script>

<style>
.dark-layout .v-select:not(.vs--single) .vs__selected {
  color: white !important;
}
</style>
